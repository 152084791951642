import React from "react";
import './about.css'
const About = () => {
  return (
    <div className="container bg-color-about space-bottom content-only">
      <div className="inner-smallspace-rg">
        <div className="row">
          <div className="col-md-12">
            <div className="content-page-design">
              <h1>Welcome to Spin Saga</h1>
              <p>
                Welcome to Spin Saga, where your journey into the world of thrilling online entertainment begins. At Spin Saga, we are dedicated to delivering an unparalleled gaming experience that combines excitement, innovation, and the thrill of winning. Our platform is designed for players who crave an immersive and rewarding environment where they can explore a wide variety of games, from classic slots to the latest casino favorites.
              </p>
              <h2>Our Mission</h2>
              <p>
                Our mission at Spin Saga is simple: to create a dynamic and entertaining space that caters to both new and experienced players. We believe in providing a gaming environment that is not only fun and engaging but also secure and trustworthy. We are committed to maintaining the highest standards of fairness and transparency, ensuring that every player has an equal chance to win.
              </p>
              <h2>Why Choose Spin Saga?</h2>
              <ul>
                <li><strong>Innovative Games:</strong> We take pride in offering a diverse selection of games that are continually updated to include the latest releases. Whether you're into traditional slots, table games, or live casino experiences, Spin Saga has something for everyone.</li>
                <li><strong>Unmatched Rewards:</strong> At Spin Saga, we believe in rewarding our players generously. From welcome bonuses to loyalty programs, we offer a range of promotions that add extra value to your gaming experience.</li>
                <li><strong>Secure & Fair Play:</strong> Your safety is our top priority. We use cutting-edge security measures to protect your personal information and ensure that our games are fair, with random outcomes every time.</li>
                <li><strong>Customer Support:</strong> Our dedicated support team is always here to help. Whether you have a question about a game, need assistance with your account, or want to learn more about our promotions, we're just a click away.</li>
              </ul>
              <h2>Our Vision</h2>
              <p>
                We envision a world where online gaming is synonymous with fun, fairness, and unforgettable experiences. At Spin Saga, we strive to set new standards in the industry by continually evolving and enhancing our platform to meet the needs of our players. We aim to be the go-to destination for those who seek top-quality entertainment and the chance to win big.
              </p>
              <h2>Join the Spin Saga Adventure</h2>
              <p>
                Spin Saga is more than just a gaming platform; it's a community of passionate players who enjoy the thrill of the game. We invite you to join our saga and embark on a journey filled with excitement, rewards, and endless possibilities.
              </p>
              <p>
                Thank you for choosing Spin Saga as your destination for online entertainment. We look forward to being a part of your adventure!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
