import React from "react";

const SweepStakes = () => {
  return (
    <div className="container bg-color-about space-bottom content-only">
      <div className="inner-smallspace-rg">
        <div className="row">
          <div className="col-md-12">
            <div className="content-page-design">
              <h1>SWEEPSTAKES RULES</h1>

              <p>
                NO PURCHASE OR PAYMENT IS NECESSARY TO PARTICIPATE. THE CHANCES
                OF WINNING ARE NOT INCREASED WITH A PURCHASE OR PAYMENT OF ANY
                KIND.
              </p>

              <p>
                Spin Saga Casino is an online platform offering Participants the
                opportunity to play games for entertainment. Additionally,
                participants may receive sweepstakes entries, known as "Saga
                Coins," as outlined in these Sweepstakes Rules. These Saga Coins
                can be utilized for Promotional Play, providing participants
                with the chance to win more Saga Coins, redeemable for actual
                prizes.
              </p>

              <p>
                SAGA COINS ARE SUBJECT TO A MINIMUM PRIZE REDEMPTION THRESHOLD
                OF $100 US DOLLARS. AMOUNTS BELOW THE THRESHOLD ARE NOT ELIGIBLE
                FOR PRIZE REDEMPTION.
              </p>

              <p>
                These Sweepstakes Rules should be read in conjunction with the
                Spin Saga Casino Terms and Conditions (T&Cs). Definitions found
                in the T&Cs carry the same meaning in these Sweepstakes Rules.
              </p>

              <p>
                PLEASE NOTE THAT THESE SWEEPSTAKES RULES INCORPORATE CLAUSE 24
                OF THE T&Cs, WHICH INCLUDE A PROVISION WAIVING THE RIGHT TO
                PURSUE ANY CLASS, GROUP OR REPRESENTATIVE CLAIM AND REQUIRING
                YOU TO PURSUE PAST, PENDING, AND FUTURE DISPUTES BETWEEN YOU AND
                US THROUGH INDIVIDUAL ARBITRATION UNLESS YOU OPT OUT WITHIN THE
                SPECIFIED TIME FRAME. SEE CLAUSE 11 OF THESE SWEEPSTAKES RULES
                FOR MORE INFORMATION.
              </p>
              <h2>ELIGIBILITY</h2>
              <p>The following definitions apply to these Sweepstakes Rules:</p>
              <ul>
                <li>
                  <strong>Eligible Participants</strong> means legal residents
                  of the Permitted Jurisdictions.
                </li>
                <li>
                  <strong>Permitted Jurisdictions</strong> means the United
                  States (excluding the states of Idaho, Michigan, Montana, and
                  Washington).
                </li>
                <li>
                  <strong>Participant</strong> means a person who participates
                  in Promotional Play.
                </li>
                <li>
                  <strong>Postal Request Code</strong> has the meaning set out
                  in clause 3.4 of these Sweepstakes Rules.
                </li>
              </ul>
              <p>
                <strong>1.2. Promotional Play</strong> means Spin Saga Casino’s
                sweepstakes promotions. Promotional Play is only open to
                Eligible Participants, who are at least eighteen (18) years old
                or the minimum age permitted in their jurisdiction (whichever is
                higher) at the time of entry.
              </p>
              <p>
                PARTICIPATION IS AUTOMATICALLY VOID WHERE PROHIBITED BY LAW.
              </p>
              <p>
                Sweepstakes are subject to all applicable federal, state,
                provincial, territorial, and local laws and regulations. It is
                the sole responsibility of a Participant to determine whether
                Sweepstakes are legal and compliant with all regulations in the
                jurisdiction in which the Participant resides.
              </p>
              <p>
                Participation constitutes the Participant’s full and
                unconditional agreement to these Sweepstakes Rules and
                Sponsor’s/Promoter’s decisions, which are final and binding in
                all matters related to the Sweepstakes.
              </p>
              <p>
                Winning a prize is contingent upon fulfilling all requirements
                set out in these Sweepstakes Rules.
              </p>
              <p>
                Employees of SpinSaga Inc, any of their respective affiliates,
                subsidiaries, holding companies, advertising agencies, or any
                other company or individual involved with the design,
                production, execution, or distribution of the Sweepstakes and
                their immediate family (spouse, parents, siblings, and children,
                whether the relationship is by birth, marriage, or adoption) and
                household members (people who share the same residence at least
                3 months of the year) are not eligible to participate in the
                Sweepstakes offered by Spin Saga Casino.
              </p>
              <h2>SPONSOR/PROMOTER</h2>
              <p>
                The Sponsor/Promoter of the Sweepstakes is SpinSaga Inc. of
                1677-34 N Franklin Ave, Ste 687, Pinedale, WY, 82941.
              </p>
              <h1>HOW TO COLLECT SAGA COINS</h1>
              <p>
                To Participate, you must have an active Customer Account with
                Spin Saga Casino or Spin Saga Casino. If you do not have a
                Customer Account, you can create one free of charge at{" "}
                <a href="https://spinsagacasino.com">
                  https://spinsagacasino.com
                </a>
                . To enter the Sweepstakes, a Participant must access Spin Saga
                Casino at{" "}
                <a href="https://spinsagacasino.com">
                  https://spinsagacasino.com
                </a>
                , sign in to their Customer Account and agree to the sweepstakes
                rules and the terms and conditions.
              </p>
              <p>
                Once they have accessed Spin Saga Casino, there are Five
                potential ways for an Eligible Participant to collect Saga
                Coins:
              </p>
              <ol>
                <li>
                  <strong>
                    Receive Saga Coins as a free bonus when purchasing Gold
                    Coins.
                  </strong>{" "}
                  Each Participant may receive Saga Coins as a bonus upon the
                  purchase of specifically marked bundles of Gold Coins. Gold
                  Coins can be used for social game play in Spin Saga Casino.
                  Gold Coins cannot be redeemed for prizes and have no monetary
                  value. The number of Saga Coins a Participant will receive as
                  a bonus for each relevant Gold Coin purchase will be stated on
                  the website purchase window. All purchases of Gold Coins are
                  final and no refunds are given.
                </li>
                <li>
                  <strong>
                    Receive Saga Coins when entering Saga Coins no-cost giveaway
                    contests on the Spin Saga Casino Facebook page.
                  </strong>{" "}
                  The Sponsor/Promoter regularly holds Saga Coins no-cost
                  giveaway contests where Participants can enter by following
                  the instructions provided in the contest. These giveaways may
                  require sharing the Facebook page posts or answering various
                  game related questions to be eligible for entry. The amount of
                  Saga Coins given away will be stated on the applicable Saga
                  Coins no-cost contest post and will be added to each contest
                  winner’s Customer Account.
                </li>
                <li>
                  <strong>
                    Receive Saga Coins by logging into your account.
                  </strong>{" "}
                  Participants can receive free Saga Coins on a daily basis by
                  taking the following steps to claim their Daily Bonus. You
                  must have an active account to claim:
                  <ul>
                    <li>Log into your Customer Account.</li>
                    <li>
                      Navigate to your account and look for the Daily Free
                      button or Navigate to the Gold Coins Page.
                    </li>
                    <li>
                      On the Gold Coins Page, click the “Claim Now” button on
                      the Daily Free Coins option. Participants can only claim a
                      Daily Bonus once per calendar day. (the daily clock resets
                      every 24 hours at 12:00 AM EST).
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    Receive Saga Coins by sending a request by post.
                  </strong>{" "}
                  Participants can receive free Saga Coins by obtaining a Postal
                  Request Code and sending a standard post card (4” X 6”) or an
                  unfolded and unlined blank piece of white paper (4” X 6”),
                  placed inside a stamped #10 envelope. The envelope MUST be
                  addressed in handwriting, to the following address:
                  <br />
                  United States Resident Claims: SPIN SAGA CASINO. XXXXX TBC;
                  <br />
                  Participants must follow these strict instructions to qualify:
                  <ul>
                    <li>
                      Handwrite the following ONLY on one side of the Request
                      Card:
                    </li>
                    <li>
                      In the top-left corner, clearly write the Postal Request
                      Code. Only one code can be written per claim. Multiple
                      codes will void your claim. (see clauses 3.4 and 3.5 of
                      these Sweepstakes Rules for further information).
                    </li>
                    <li>
                      Below the Postal Request Code, handwrite the following in
                      this order:
                      <ul>
                        <li>
                          Your full name as shown on your government issued
                          identification. This must match your account.
                        </li>
                        <li>
                          The email address registered on your Spin Saga Casino
                          account.
                        </li>
                        <li>
                          Your residential address registered on your Spin Saga
                          Casino account.
                        </li>
                        <li>
                          The following statement: “I wish to request to receive
                          Saga Coins to participate in the sweepstakes
                          promotions offered by Spin Saga Casino. By submitting
                          this request, I hereby declare that I have read,
                          understood and agree to be bound by Spin Saga Casino’s
                          Terms and Conditions and Sweepstakes Rules.”
                        </li>
                      </ul>
                    </li>
                    <li>
                      Handwrite your return address on the front of the envelope
                      and the words: “Sweepstakes Entries”.
                    </li>
                    <li>
                      A Participant must ensure that their handwriting is
                      legible. If the Participant’s handwriting is not legible,
                      the entry will be void and the Saga Coins will not be
                      added to the Participant’s Customer Account. The
                      legibility of a Participant’s handwriting will be
                      determined by Sponsor/Promoter in its sole discretion.
                    </li>
                    <li>
                      FOR THE AVOIDANCE OF DOUBT, COMBINED REQUESTS SENT VIA
                      PACKAGE, PARCEL OR THE SAME ENVELOPE CONTRADICT THE
                      REQUIREMENTS SET OUT ABOVE AND WILL BE CONSIDERED INVALID.
                    </li>
                    <li>
                      Requests are limited to one request per outer envelope.
                      For each Request a Participant submits in accordance with
                      the above requirements, the Participant will receive 5
                      Saga Coins. The Saga Coins will be credited to the
                      Participant’s Customer Account. THE REQUEST MUST ONLY BE
                      MADE BY THE PARTICIPANT AND MUST BE POSTED FROM THE SAME
                      STATE OR PROVINCE AS THE PARTICIPANT’S VERIFIED
                      RESIDENTIAL ADDRESS. Requests made by any other individual
                      or any entity (including but not limited to commercial
                      sweepstakes subscription notification and/or entering
                      services) or posted from a state or province different to
                      the Participant’s verified residential address will be
                      declared invalid and Saga Coins will not be added to the
                      Participant’s Customer Account. Tampering with the entry
                      process or the operation of the Sweepstakes, including but
                      not limited to the use of any device or software to
                      automate the Saga Coins request/entry process or to
                      unfairly assist the Participant with meeting the
                      handwriting requirement, is prohibited and any
                      requests/entries deemed by Sponsor/Promoter, at its sole
                      discretion, to have been submitted in this manner will be
                      void. Where there is an issue or fault with an envelope
                      used by a Participant to request free Sweepstakes Coins,
                      such that it does, or is reasonably likely to, cause
                      damage to, or otherwise adversely affect, equipment used
                      or procedures implemented to process mailed in requests
                      (for example, handmade envelopes that are not suitable for
                      machine processing due to poor quality or non-standard
                      adhesive or paper), such entry will be void. In the event
                      a dispute regarding the identity of the individual who
                      actually submitted a request or whether a request
                      satisfies the requirements set out above cannot be
                      resolved to Sponsor/Promoter’s satisfaction, the affected
                      request/entry will be deemed ineligible, the request will
                      not be processed and void.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    A Postal Request Code is a unique combination of 15 digits
                    that Participants can obtain by following the steps outlined
                    in clause 3.5 of these Sweepstakes Rules.
                  </strong>{" "}
                  A Postal Request Code is unique, can only be used once and
                  expires 90 days after it is displayed. The Postal Request Code
                  will be able to be accessed again after it has been displayed
                  at time of request. The purpose of a Postal Request Code is to
                  assist with the efficient and effective processing of a
                  Participant’s Request. Request Cards containing an expired
                  Postal Request Code at the time of processing will be void. A
                  POSTAL REQUEST CODE CAN ONLY BE USED BY THE PARTICIPANT WHOSE
                  CUSTOMER ACCOUNT WAS USED TO GENERATE THAT POSTAL REQUEST
                  CODE. Any Participant suspected by Sponsor/Promoter to have
                  directly or indirectly provided a Postal Request Code to
                  another person may, in the sole discretion of
                  Sponsor/Promoter, be disqualified and lose their eligibility
                  to Participate in future Sweepstakes.
                </li>
              </ol>
              <p>
                A Postal Request Code can be obtained by following each of these
                steps:
              </p>
              <ol>
                <li>Sign into your Customer Account.</li>
                <li>
                  Click on the “Postal Request Code” link in the My Account
                  section.
                </li>
                <li>
                  The Postal Request Code is then displayed for a period of
                  time.
                </li>
                <li>
                  Record the Postal Request Code as it will not be accessible
                  again.
                </li>
                <li>
                  Once your postal request code has been redeemed, you will be
                  able to request a new Postal Request Code.
                </li>
              </ol>
              <p>
                The amount of Saga Coins a Participant has will be displayed in
                their Customer Account on the website or on the APP. The amount
                of Saga Coins to be allocated to Participants can be changed at
                any time by the Sponsor/Promoter in its sole discretion.
              </p>

              <p>
                Sponsor/Promoter is not responsible for lost, late, incomplete,
                invalid, unintelligible or misdirected Saga Coins requests or
                allocations.
              </p>

              <p>
                In the event of any disputes regarding the registration of a
                Customer Account, the authorized account holder of the email
                address used to register the Customer Account will be deemed to
                be the Participant. The “authorized account holder” is the
                natural person who has been assigned the email address by an
                internet access provider, online service provider or other
                organization responsible for assigning email addresses for the
                domain associated with the submitted address.
              </p>

              <p>
                Use of any automated or other system(s) to Participate, to
                acquire Saga Coins or play the games is prohibited and will
                result in disqualification and loss of eligibility to
                Participate in the games and any Gold Coins or Saga Coins
                associated with the account.
              </p>

              <p>
                Saga Coins are only valid for sixty (60) days from the date a
                Participant last logged on to their Customer Account and will
                then automatically expire.
              </p>

              <p>
                Saga Coins may also be forfeited if a Participant’s Customer
                Account is closed or suspended for any reason, or at the
                Sponsor/Promoter’s discretion.
              </p>

              <h3>USING SAGA COINS TO PLAY GAMES</h3>
              <p>
                Participants with Saga Coins can use those Saga Coins to play
                games within Spin Saga Casino for a chance to win additional
                Saga Coins. ONLY Saga Coins won through game play can be
                redeemed for prizes.
              </p>

              <p>
                Saga Coins that have been received as part of a package when
                purchasing Gold Coins, Daily Bonuses, Postal Requests, Gold Coin
                play or via no-cost Giveaways do not have any monetary value and
                cannot be used to redeem any prizes.
              </p>

              <p>
                Within Spin Saga Casino there are different games. The amount of
                Saga Coins required to play each game will be detailed next to
                the game. A Participant may be able to increase the amount of
                Saga Coins they play the game with so they can increase their
                potential winning eligibility. This does not increase your
                chances of winning.
              </p>

              <p>
                Only games played with Saga Coins provide the opportunity to win
                additional Saga Coins which can then be used to redeem real
                prizes. The prize that can be won while playing a game will be
                shown in Spin Saga Casino by clicking the “Win Table” or “Pay
                Table” button, denominated as “Saga Coins”.
              </p>

              <p>
                Each Saga Coin that has been won through game play (rather than
                collected using one of the methods described in clause 3 of
                these Sweepstakes Rules) can be redeemed for a prize with value
                being equivalent to US$1.
              </p>

              <p>
                Sponsor’s/Promoter’s decisions as to the administration and
                operation of the Sweepstakes, the game and the amount of
                winnings are final and binding.
              </p>

              <p>
                Unless Sponsor/Promoter requires otherwise, any Saga Coins
                allocated to a Participant are required to be played at least
                once before they are eligible to be redeemed for prizes.
                Sponsor/Promoter may, in its sole discretion, require that any
                Saga Coins allocated to a Participant must be played a greater
                number of times (not exceeding 20) in any combination of Saga
                Coins games before they are eligible to be redeemed for prizes.
              </p>

              <p>
                Sponsor/Promoter reserves the right to change the prize win
                rates and odds of any of the Sweepstakes at any time. A
                Participant can see the actual and current details by clicking
                on the “Win Table” or “Pay Table” button. It is a Participant’s
                responsibility to check the prize win rate on each occasion
                before they participate.
              </p>
              <h2>VERIFICATION AND CONFIRMATION OF POTENTIAL WINNERS</h2>
              <p>
                POTENTIAL SWEEPSTAKES WINNERS ARE SUBJECT TO VERIFICATION BY
                SPONSOR/PROMOTER (IN ANY MANNER IT MAY CHOOSE) AND THE DECISIONS
                OF SPONSOR/PROMOTER ARE FINAL AND BINDING IN ALL MATTERS RELATED
                TO THE SWEEPSTAKES. A PARTICIPANT IS NOT A WINNER OF ANY PRIZE,
                EVEN IF THE ONLINE SCREEN INDICATES THEY ARE, UNLESS AND UNTIL
                THE PARTICIPANT’S ELIGIBILITY AND THE POTENTIAL WINNING PLAY HAS
                BEEN VERIFIED AND THE PARTICIPANT HAS FULLY COMPLIED WITH THESE
                SWEEPSTAKES RULES AND BEEN NOTIFIED THAT VERIFICATION IS
                COMPLETE. SPONSOR/PROMOTER WILL NOT ACCEPT SCREEN SHOTS OR OTHER
                PURPORTED EVIDENCE OF WINNING IN LIEU OF ITS VALIDATION PROCESS.
                Potential prize winners must comply with these Sweepstakes Rules
                and winning is contingent upon fulfilling all requirements. A
                potential prize winner may be required to sign and return to
                Sponsor/Promoter, an affidavit/declaration of eligibility, and
                liability/publicity release (except where prohibited) in order
                to claim his/her prize (if applicable). If a potential winner
                cannot be contacted, fails to properly execute and return the
                affidavit/declaration of eligibility and liability/publicity
                release within the required time period (if applicable), fails
                to comply with these Sweepstakes Rules, or if the prize or prize
                notification is returned as undeliverable, that potential winner
                forfeits the prize. Redeemed prizes can only be sent to the
                registered address on the potential winner’s account. The
                Sponsor/Promoter is not responsible for incorrectly completed
                address information held on participants and potential winner’s
                accounts. Incorrect information that results in an incomplete
                delivery will result in the forfeit of the prize. In the event
                that a potential winner of a Sweepstakes prize is disqualified
                for any reason, Sponsor/Promoter may, in its sole discretion,
                reseed the prize back into the Sweepstakes.
              </p>
              <h2>PRIZES</h2>
              <p>
                A Participant’s Saga Coins balance is shown in the Participant’s
                Customer Account. Prizes are not awarded until redeemed. A
                Participant has no rights to Saga Coins that have not been
                redeemed. Where a Participant has chosen to redeem prizes for
                cash, the payment will be made in USD to the financial account
                or online wallet from which you purchased Gold Coins, or if this
                is not technically possible, then to an alternative bank account
                you nominate. Sponsor/Promoter reserves the right to require the
                use of the same payment method for redemption of prizes as was
                used to purchase Gold Coins, or a specific payment method at
                Sponsor/Promoter's discretion. The Sponsor/Promoter reserves the
                right to request additional identification or proof of account
                ownership when issuing payment to an account NOT originally used
                to purchase Gold Coins. Where a Participant has chosen to redeem
                prizes for gift cards, the gift cards will be allocated to the
                email address that the Participant has registered against their
                Customer Account or in the case of physical gift cards, to the
                registered residential address on the Customer Account.
                Sponsor/Promoter will only process a prize redemption if the
                redeemed value of the prize is US$100 or more (i.e. a minimum of
                100 eligible Saga Coins). Consequently, a Participant’s Customer
                Account must have a balance of at least 100 Saga Coins before
                requesting a prize redemption. This balance must be comprised of
                won Saga Coins and not a combination in part or in whole of Saga
                Coins issued by any means outlined in section 3. TO BE ELIGIBLE
                FOR A SAGA COINS PRIZE OR A SAGA COINS PRIZE REDEMPTION: A
                PARTICIPANT MUST BE AN ELIGIBLE PARTICIPANT; AND THE
                PARTICIPANT’S DETAILS MUST MATCH THOSE OF THE PARTICIPANT’S
                CUSTOMER ACCOUNT. In New York and Florida, the maximum
                redemption value of a Saga Coins prize won on any one spin or
                play, via a Participant’s participation in the Sweepstakes is
                US$5,000. Any prize valued in excess of US$5,000 will be reduced
                to a maximum value of US$5,000. Sponsor/Promoter reserves the
                right, at its sole discretion, to limit a Participant’s
                redemption of Saga Coins to US$10,000 per day or any other
                amount over any time that Sponsor/Promoter considers necessary
                to satisfy its regulatory obligations or the requirements of its
                partners and suppliers. No more than the stated number of prizes
                will be awarded. Sponsor/Promoter is not responsible for any
                taxes or fees associated with a prize redemption. Participants
                are responsible for all applicable taxes and fees associated
                with prize receipt and/or redemption. Sponsor/Promoter is not
                responsible for any foreign exchange transaction fees, charges
                or related costs that may be incurred as a result of, or in
                relation to, a prize redemption, including but not limited to
                any losses or additional costs arising from foreign exchange
                fluctuations. Saga Coins are not transferable and no
                substitution will be made except as provided herein at the
                Sponsor’s/Promoter’s sole discretion. Sponsor/Promoter reserves
                the right to substitute the listed prize of equal or greater
                value for any reason owing to circumstances outside
                Sponsor’s/Promoter’s reasonable control. Prize winners will be
                notified by private message or email.
              </p>

              <h2>ENTRY CONDITIONS AND RELEASE</h2>
              <p>By Participating, each Participant agrees to:</p>
              <p>Comply with and be bound by:</p>
              <ul>
                <li>The T&Cs</li>
                <li>These Sweepstakes Rules.</li>
                <li>
                  Any decisions of the Sponsor/Promoter which are binding and
                  final.
                </li>
              </ul>
              <p>
                Release and hold harmless the Sponsor/Promoter and its parent,
                subsidiary, and affiliated companies, the prize suppliers and
                any other organizations responsible for sponsoring, fulfilling,
                administering, advertising or promoting the Sweepstakes, and all
                of their respective past and present officers, directors,
                employees, agents and representatives (collectively, the
                Released Parties) from and against any and all claims, expenses,
                and liability, including but not limited to negligence and
                damages of any kind to persons and property, including but not
                limited to invasion of privacy (under appropriation, intrusion,
                public disclosure of private facts, false light in the public
                eye or other legal theory), defamation, slander, libel,
                violation of right of publicity, infringement of trademark,
                copyright or other intellectual property rights, property
                damage, or death or personal injury arising out of or relating
                to a Participant’s entry, creation of an entry or submission of
                an entry, participation in the Sweepstakes, acceptance or use or
                misuse of prizes (including any travel or activity related
                thereto) and/or the broadcast, exploitation or use of entry; and
              </p>
              <p>
                Indemnify, defend and hold harmless the Sponsor/Promoter from
                and against any and all claims, expenses, and liabilities
                (including reasonable attorneys/legal fees) arising out of or
                relating to a Participant’s participation in the Sweepstakes
                and/or Participant’s acceptance, use or misuse of prizes.
              </p>

              <h2>PUBLICITY</h2>
              <p>
                Except where prohibited, participation in the Sweepstakes
                constitutes each Participant’s consent to Sponsor’s/Promoter’s
                and its agents’ use of Participant’s name, likeness, photograph,
                voice, opinions and/or hometown and state/province/territory for
                promotional purposes in any media, worldwide, without further
                payment, notice or consideration.
              </p>

              <h2>GENERAL CONDITIONS</h2>
              <p>
                Sponsor/Promoter reserves the right to cancel, suspend and/or
                modify the Sweepstakes or these Sweepstakes Rules, or any part
                of the Sweepstakes or these Sweepstakes Rules, with immediate
                effect owing to circumstances outside its reasonable control and
                only where circumstances make it unavoidable if any fraud,
                technical failures or any other factor beyond
                Sponsor’s/Promoter’s reasonable control impairs the integrity or
                proper functioning of the Sweepstakes, as determined by
                Sponsor/Promoter in its sole discretion.
              </p>
              <p>
                Sponsor/Promoter reserves the right in its sole discretion to
                disqualify any individual it finds to be tampering with the
                entry process or the operation of the Sweepstakes or to be
                acting in violation of these Sweepstakes Rules or any other
                promotion or in an unsportsmanlike or disruptive manner.
              </p>
              <p>
                Any attempt by any person to deliberately undermine the
                legitimate operation of the Sweepstakes may be a violation of
                criminal and civil law and, should such an attempt be made,
                Sponsor/Promoter reserves the right to seek damages from any
                such person to the fullest extent permitted by law.
                Sponsor’s/Promoter’s failure to enforce any term of these
                Sweepstakes Rules shall not constitute a waiver of that
                provision.
              </p>
              <p>
                In all other cases, the Sponsor/Promoter reserves the right to
                cancel, suspend and/or modify the Sweepstakes. Any notice
                regarding cancellation, suspension and/or modification will be
                posted on Spin Saga Casino at{" "}
                <a href="https://Spinsagacasino.com">
                  https://Spinsagacasino.com
                </a>
                .
              </p>
              <p>
                Whenever we amend these Sweepstakes Rules in a way that would
                limit your current rights or which may be to your detriment, we
                will notify you upon your next visit to the Platform and you
                will be required to reconfirm your acceptance prior to
                participating in any Promotional Play. If you do not agree to
                the amended Sweepstakes Rules, you may not participate in any
                further Promotional Play and access to your account may be
                restricted.
              </p>

              <h2>LIMITATIONS OF LIABILITY</h2>
              <p>
                Insofar as permitted by law in the respective territories, the
                Released Parties (as defined above) are not responsible for:
              </p>
              <ul>
                <li>
                  Any incorrect or inaccurate information, whether caused by a
                  Participant, printing error or by any of the equipment or
                  programming associated with or utilized in the Sweepstakes;
                </li>
                <li>
                  Technical failures of any kind, including, but not limited to
                  malfunctions, interruptions, or disconnections in phone lines
                  or network hardware or software;
                </li>
                <li>
                  Unauthorized human intervention in any part of the entry
                  process or the Sweepstakes;
                </li>
                <li>
                  Technical or human error which may occur in the administration
                  or operation of the Sweepstakes;
                </li>
                <li>
                  Any injury or damage to persons or property which may be
                  caused, directly or indirectly, in whole or in part, from
                  Participant’s participation in the Sweepstakes or receipt or
                  use or misuse of any prize.
                </li>
              </ul>
              <p>
                If for any reason a Participant’s Saga Coins or winning play is
                confirmed to have been erroneously deleted, lost, or otherwise
                destroyed or corrupted, Participant’s sole remedy is replacement
                by Sponsor/Promoter of an equivalent amount of Sweepstakes
                Coins.
              </p>
              <h2>DISPUTES</h2>
              <p>
                Clause 24 of the T&Cs (Dispute Resolution and Agreement to
                Arbitrate on an Individual Basis) is incorporated herein by
                reference with the same force and effect as though fully set
                forth herein. By agreeing to these Sweepstakes Rules, you agree
                to be bound by the dispute resolution and arbitration provisions
                contained in clause 24 of the T&Cs.
              </p>

              <h2>OTHER</h2>
              <p>
                Under no circumstances will any Participant be permitted to
                obtain awards for, and Participant hereby waives all rights to
                claim, punitive, incidental or consequential damages, or any
                other damages, including attorneys’ fees, other than
                Participant’s actual out-of-pocket expenses (i.e., costs
                associated with participating in this Sweepstakes), and
                Participant further waives all rights to have damages multiplied
                or increased. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR
                EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                SO THE ABOVE MAY NOT APPLY TO YOU.
              </p>
              <p>
                All issues and questions concerning the construction, validity,
                interpretation and enforceability of these Sweepstakes Rules, or
                the rights and obligations of the Participant and
                Sponsor/Promoter in connection with the Sweepstakes, are
                governed by, and construed in accordance with, the laws of the
                State of Delaware, without giving effect to any choice of law or
                conflict of law rules (whether of the State of Delaware or any
                other jurisdiction), which would cause the application of the
                laws of any jurisdiction other than the State of Delaware.
              </p>
              <p>
                To ensure fairness and the integrity of the promotion to all
                Participants, the Sponsor/Promoter will respond to questions to
                Customer Support via this form and may post
                updates/communications on its Facebook page.
              </p>
              <p>
                Any Participant posting or seen to be posting comments on
                Sponsor’s/Promoter’s Facebook page, other community pages
                operated by the Sponsor/Promoter or elsewhere during the
                promotion that are considered bullying, spiteful or upsetting to
                other Participants, players and fans of Spin Saga Casino or
                directly aimed at the Sponsor/Promoter, will have their comments
                removed and will be disqualified from the Sweepstakes. The
                Sponsor/Promoter reserves the right to alert Facebook or the
                channel operator to any such behaviour and Participant may have
                his/her account frozen pending investigation.
              </p>

              <h2>PARTICIPANT’S PERSONAL INFORMATION</h2>
              <p>
                Information collected from Participants is subject to the
                Sponsor’s/Promoter’s Privacy Policy which is available at{" "}
                <a
                  href="https://play.spinsagacasino.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 https://play.spinsagacasino.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SweepStakes;
