import React from "react";

const Privacy = () => {
  return (
    <div>
      <div className="container bg-color-about space-bottom content-only">
        <div className="inner-smallspace-rg">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h1>Privacy Policy</h1>
                <p>
                  SpinSaga Inc. (“SpinSaga,” “Company,” “we,” “us,” or “our”) is
                  a brand operated by CYLON TECHSOFT PRIVATE LIMITED that values
                  and safeguards the privacy of visitors to SpinSaga.com
                  (“Site”) and users of the SpinSaga mobile application
                  available on iOS and Android (the “App,” together with the
                  Site, the “Services”). We have a strict policy in place to
                  protect the privacy of our Visitors and Players (as defined in
                  this policy). This Privacy Policy outlines the types of
                  information we may collect from you and how we collect, use,
                  maintain, protect, and disclose such information. The Privacy
                  Policy also covers certain rights that you may have over
                  information we collect from you. Terms used in this Privacy
                  Policy that are not defined have the same meaning as defined
                  in the SpinSaga Terms and Conditions (“Terms”).
                </p>
                <p>
                  By accessing or using the Services, you are agreeing to this
                  Privacy Policy. If you do not agree with our policies and
                  practices, your only option is to not use the Services. As
                  stated below, this policy may change periodically. Please
                  check the policy for updates and continued use of the Services
                  after changes are made will be considered acceptance of those
                  changes.
                </p>
                <h2>1. Policy Overview</h2>
                <p>
                  Our commitment to protecting the privacy of individuals who
                  visit our Site (“Visitors”) and users who have registered for
                  an account or downloaded the App (“Players”) is outlined in
                  this Policy. This policy applies to information that is
                  automatically collected via the Services or that is provided
                  to us by Visitors or Players.
                </p>
                <p>
                  However, this Privacy Policy does not apply to:
                  <ul>
                    <li>
                      Offline Information. Information collected by us offline
                      or through any other means, including on any other website
                      operated by SpinSaga or any third party (including our
                      affiliates and subsidiaries) is not subject to this
                      Privacy Policy.
                    </li>
                    <li>
                      Third Parties. We are not responsible for the privacy
                      policies or other practices employed by websites linked
                      to, or from, our Site or App, nor the information or
                      content contained therein. We encourage you to read the
                      privacy statements of any linked third party.
                    </li>
                  </ul>
                </p>
                <p>
                  This Privacy Policy is a legally binding contract between the
                  Operator and anyone who accesses or uses the Services. By
                  using the Services, you are agreeing to be bound by the terms
                  of this Privacy Policy, that may be amended from time to time,
                  and to the collection, storage, use, and protection of
                  personal information as described herein. If you do not wish
                  to be bound by this Privacy Policy, please discontinue use of
                  the Services immediately.
                </p>
                <p>
                  SpinSaga reserves the right to amend this Privacy Policy and
                  the Terms and Conditions from time to time at our sole
                  discretion. We will notify you of any material change by
                  posting the revised Privacy Policy on the Services. Any
                  changes to the Privacy Policy will be effective immediately
                  upon notification. For any non-material change that does not
                  require notification, your continued use and access of the
                  Services subsequent to such change or amendment will be deemed
                  as acceptance of such change or amendment. We encourage you to
                  visit this Privacy Policy from time to time to ensure that you
                  are aware of the current version and any changes that we may
                  make.
                </p>
                <h2>2. Information Collected</h2>
                <p>
                  At SpinSaga, we collect a variety of information about our
                  Visitors and Players, including:
                  <ul>
                    <li>
                      Personal Data, which may include your name, email address,
                      phone number or any other identifier that may be used to
                      contact you online or offline;
                    </li>
                    <li>
                      Non-Personal Data, which may be information about you but
                      does not identify you individually;
                    </li>
                    <li>
                      Usage details, information about your internet connection
                      and the equipment you use to access our Services.
                    </li>
                    <li>
                      Location of you or your device at the time of accessing
                      certain services or sections of the site or app.
                    </li>
                  </ul>
                  We collect this information from you directly when you provide
                  it to us, automatically as you navigate through the Services,
                  or from third parties such as our business partners.
                </p>
                <p>
                  Information That You Provide Directly to Us:
                  <ul>
                    <li>
                      When you register to use our Services or request certain
                      materials, we may require you to provide Personal Data,
                      such as your name, email address, phone number, driver's
                      license, birth date, gender, and unique identifiers, like
                      a username or password. We may also receive Personal Data
                      from records and copies of your correspondence, dialog
                      boxes or chats, your responses to surveys or feedback
                      requests, or details of transactions you conduct through
                      our Services.
                    </li>
                    <li>
                      Additionally, we may obtain Personal Data if you choose to
                      publish or display information (referred to as “User
                      Contributions”) on public areas of our Services or
                      transmit it to other Players or third parties. Your User
                      Contributions are posted and transmitted to others at your
                      own risk, and we cannot control the actions of other
                      Players with whom you choose to share your User
                      Contributions. As a result, we cannot guarantee that your
                      User Contributions will not be viewed by unauthorized
                      persons.
                    </li>
                  </ul>
                </p>
                <p>
                  Automatically Collected Information Through Your Use of the
                  Services:
                  <ul>
                    <li>
                      While you use our Services, we may gather certain
                      demographic data and information about your interaction
                      history, devices, IP address, operating system, browser
                      type, browsing behavior and patterns, using automatic data
                      collection technologies. This information is statistical
                      in nature and does not include personal information, but
                      we may retain it or associate it with personal information
                      we gather through other methods. We use this information
                      to enhance our Services and provide a better and more
                      customized service. It allows us to estimate our audience
                      size and usage patterns, store information about your
                      preferences, and identify you when you revisit our
                      Services.
                    </li>
                    <li>
                      The automatic data collection technologies we use may
                      include:
                      <ul>
                        <li>
                          Cookies (or browser cookies). SpinSaga uses cookies on
                          its Services. A cookie is a small file placed on your
                          device's hard drive or internal storage. We use
                          cookies to track and report on your activity on the
                          Site or App. Through cookies, we can gather data that
                          we use to improve the Services, keep track of return
                          visits to our website, collect and report on aggregate
                          statistical data, authenticate your login credentials,
                          or manage multiple instances of the Site in a single
                          browser. We may also collect other data, such as the
                          page or site that referred you to the Site, the date
                          and time of your visit, and your current IP address.
                          You can reject browser cookies by enabling the
                          appropriate setting in your browser. However, if you
                          do so, you may not be able to access certain parts of
                          our Site, or the Services and functionality may be
                          limited. Unless you have modified your browser's
                          settings to refuse cookies, our system will issue
                          cookies when you direct your browser to our Site.
                        </li>
                        <li>
                          Our Site and e-mails may contain small electronic
                          files known as web beacons (also referred to as clear
                          gifs, pixel tags, and single-pixel gifs) that permit
                          us to count users who have visited those pages or
                          opened an email and for other related website
                          statistics. Occasionally, we connect personal
                          information to information gathered in our log files
                          to improve our Site and Services. If we do so, we
                          would treat the combined information in accordance
                          with this Privacy Policy. Log files that we collect
                          may include IP addresses, browser type, domain names,
                          access times, and physical location.
                        </li>
                        <li>
                          Heat mapping services are used on our Site to display
                          the areas of a page where Players most frequently move
                          the mouse or click. This shows where the points of
                          interest are. These services make it possible to
                          monitor and analyze web traffic and keep track of
                          Player behavior. Some of these services may record
                          sessions and make them available for later visual
                          playback.
                        </li>
                        <li>
                          Our Services collect real-time information about the
                          location of your device. Disabling location data or
                          falsifying your location will impair the functions of
                          Services, including prohibiting you from entering
                          contests, depositing and withdrawing funds.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  We do not collect personal information automatically, but we
                  may link this information to personal information about you
                  that we collect from other sources you provide to us.
                </p>

                <h2>3. How We Use Your Data</h2>
                <p>
                  SpinSaga may use aggregated (anonymized) information about its
                  users and non-personal information without restriction. We
                  will limit the collection of your Personal Data to the extent
                  necessary for the purposes identified by SpinSaga. Unless you
                  consent or we are required by law, we will only use the
                  Personal Data for the purposes for which it was collected. If
                  SpinSaga processes your Personal Data for another purpose
                  later on, SpinSaga will seek your further legal permission or
                  consent, unless the other purpose is compatible with the
                  original purpose. We will retain your Personal Data only for
                  as long as necessary to serve those purposes and will also
                  retain and use your Personal Data as necessary to comply with
                  our legal obligations and resolve disputes.
                </p>
                <p>
                  We use information, including Personal Data, about you for
                  various purposes, including:
                </p>
                <ul>
                  <li>
                    Collecting or disbursing payment for transactions made
                    through the Services;
                  </li>
                  <li>Generally enabling you to use our Services;</li>
                  <li>
                    Providing you with information, products, or services you
                    request;
                  </li>
                  <li>
                    Customizing and personalizing the content we deliver to you;
                  </li>
                  <li>Providing customer service and support;</li>
                  <li>
                    Sending you administrative messages about the Services;
                  </li>
                  <li>
                    Carrying out our obligations and enforcing our rights
                    arising from any contracts entered into between you and us,
                    including billing and collection;
                  </li>
                  <li>
                    Notifying you of changes to our Services or any products or
                    services we offer or provide through it;
                  </li>
                  <li>
                    Monitoring and analyzing trends, usage, and activities in
                    connection with the Services;
                  </li>
                  <li>
                    Investigating and preventing fraudulent transactions,
                    unauthorized access to the Services, violations of the
                    Terms, and other illegal activities;
                  </li>
                  <li>
                    Sharing testimonials or reviews that you may have posted to
                    the Services or other SpinSaga owned social media pages;
                  </li>
                  <li>
                    For any other purpose with your consent or as permitted by
                    our Terms and Conditions and this Agreement;
                  </li>
                  <li>
                    Contacting you about our own and third-parties' goods and
                    services that may be of interest to you.
                  </li>
                </ul>
                <p>
                  If you do not want us to use your information for contacting
                  you related to goods or services of interest or promotional
                  purposes, please adjust your user preferences in your account
                  profile or by following the unsubscribe instructions included
                  in our marketing communications.
                </p>

                <h2>4. Sharing of Your Information</h2>
                <p>
                  We may share information about our Visitors and Players that
                  is aggregated and does not identify individuals, including
                  anonymized contest data, without restriction. We may share
                  Personal Data that we collect or that you provide:
                </p>
                <ul>
                  <li>
                    With other SpinSaga Players to share your public profile.
                  </li>
                  <li>With our subsidiaries and affiliates.</li>
                  <li>
                    With third-party service providers for account
                    authentication or management.
                  </li>
                  <li>
                    With contractors, service providers, and other third parties
                    we use to support our business, who are obligated to keep
                    personal information confidential and use it only for the
                    purposes for which we disclose it to them. These third
                    parties may include payment processors and analytics
                    contractors.
                  </li>
                  <li>
                    With a buyer or other successor in the event of a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of SpinSaga's assets,
                    whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal
                    information held by SpinSaga about our Visitors and Players
                    is among the assets transferred. If we make such a change,
                    we will notify you by prominently displaying a notice on our
                    Services or by email to the primary address associated with
                    your account. Any successor in interest to this Privacy
                    Policy will be bound by the Privacy Policy at the time of
                    transfer.
                  </li>
                  <li>
                    To comply with any court order, law, or legal process,
                    including to respond to any government or legal request.
                  </li>
                  <li>
                    To enforce or apply our Terms and other agreements,
                    including for billing and collection purposes.
                  </li>
                  <li>
                    We may disclose your personal information if we believe it
                    is necessary or appropriate to protect the rights, property,
                    or safety of SpinSaga, our Players, or others. This may
                    include sharing information with other companies and
                    organizations for the purposes of fraud protection and
                    credit risk reduction.
                  </li>
                </ul>

                <h2>5. Data Security</h2>
                <p>
                  To protect your Personal Data from unauthorized access, use,
                  alteration, or disclosure, we have implemented
                  industry-standard technical and organizational measures. These
                  measures are designed to provide an appropriate level of
                  security based on the risk associated with processing your
                  Personal Data. All information that you provide to us is
                  stored on secure servers behind firewalls.
                </p>
                <p>
                  The security of your information also depends on you. You are
                  responsible for keeping your username and password
                  confidential. Please do not share your password with anyone.
                  When sharing information in public areas of the Services such
                  as message boards or company profile pages, please exercise
                  caution as any Player or Visitor of the Services may view this
                  information depending on where it is posted. SpinSaga staff do
                  not have access to player passwords. SpinSaga support staff or
                  personnel will never ask you for your password or part of your
                  password to identify you or to assist you with access to your
                  account. Never disclose your password to anyone via phone,
                  messenger, SMS, WhatsApp or any other communication method.
                </p>
                <p>
                  Although we take steps to protect your personal information,
                  we cannot guarantee the complete security of information
                  transmitted through the internet. Any transmission of Personal
                  Data is at your own risk. We are not responsible for the
                  circumvention of any privacy settings or security measures
                  contained on the Services.
                </p>

                <h2>6. Choices You Have for Your Personal Data</h2>
                <p>
                  To review or modify your personal information, you can access
                  your account profile page. You may also request access to,
                  correction or deletion of any personal information by sending
                  an email to us. However, we may decline a request to change
                  information if we believe it would violate any legal
                  requirement or cause the information to be incorrect. If you
                  delete User Contributions from the Services, copies of your
                  contributions may still be available on cached or archived
                  pages or may have been copied or stored by other Players or
                  Visitors. Database entries of User Contributions will remain
                  in place for record keeping and legal purposes, although will
                  not be publicly accessible.
                </p>
                <p>
                  You have the option to allow or block the App from collecting
                  real-time information about your device's location through
                  your device's privacy settings. Blocking location information
                  may restrict access to some parts of the Services or cause
                  them to malfunction.
                </p>
                <p>
                  You can adjust your browser settings to refuse all or some
                  cookies, or to be alerted when cookies are sent. Disabling or
                  blocking cookies or other tracking technologies may restrict
                  access to some parts of the Services or cause them to
                  malfunction.
                </p>
                <p>
                  SpinSaga only sends permission-based email and provides
                  Players with the option to opt-out or change preferences
                  through a link in all non-transactional email messages. Some
                  communications, such as important account notifications and
                  billing information, are considered transactional and are
                  necessary for all SpinSaga Players. To unsubscribe from
                  transactional communications, you must cancel your SpinSaga
                  account.
                </p>
                <p>
                  At this time, SpinSaga does not respond to "Do Not Track"
                  signals or other similar mechanisms. To learn more about "Do
                  Not Track," you can visit www.allaboutdnt.com
                </p>
                <h2>7. Changes to the Privacy Policy</h2>
                <p>
                  We reserve the right to update or modify this Privacy Policy
                  at any time and without prior notice. Any changes to this
                  Privacy Policy will be posted on this page with a notice that
                  the Privacy Policy has been updated on the Site home page or
                  on the App. If we make any material changes to how we treat
                  your Personal Data, we will notify you by email to the primary
                  email address specified in your account, through a prominent
                  notice on the Site home page, or on the App. The revised
                  Privacy Policy will be effective immediately upon posting, and
                  the date of the last revision will be identified at the top of
                  this page. You are responsible for ensuring that we have an
                  up-to-date, active, and deliverable email address for you and
                  for periodically reviewing this Privacy Policy to be informed
                  of any changes. Your continued use of our Services after the
                  posting of any amendment, modification, or change to this
                  Privacy Policy constitutes your acceptance of the amendments
                  to this Privacy Policy. If you do not accept the terms of this
                  Privacy Policy or any modified version of this Privacy Policy,
                  you may choose to discontinue the use of the Services.
                </p>

                <h2>8. Geolocation Data</h2>
                <p>
                  If you use location-based services, we may collect, process,
                  maintain, and use your location data to provide and enhance
                  such services. By using these services, you consent to this
                  data usage. If you wish to withdraw your consent, you may turn
                  off location settings or inform us in writing. However, please
                  note that if you do so, your access to and use of the
                  Services, the Online Software, and App Software may be
                  restricted, blocked, or terminated.
                </p>

                <h2>9. Further Information</h2>
                <p>
                  For more information, please contact{" "}
                  <a href="mailto:support@SpinSaga.com">support@SpinSaga.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
