import React from "react";
const ResponsibleGaming = () => {
  return (
    <div>
      <div className="container bg-color-about space-bottom content-only">
        <div className="inner-smallspace-rg">
          <div className="row">
            <div className="col-md-12">
              <div className="content-page-design">
                <h1>Responsible Gaming</h1>
                <p>
                  SpinSaga offers sweepstakes and social casino games on its site and application. We advise all customers to please engage in responsible gaming and only submit sweepstakes entries at a value or total amount that is within your means.
                </p>
                <p>
                  If you or someone you know has a gambling problem, assistance is available. Call (1-800-GAMBLER). For additional help and support regarding gambling addiction, please reach out to:
                </p>
                <ul>
                  <li>The National Council on Problem Gambling at 1-800-522-4700 or visit <a href="http://www.ncpgambling.org">www.ncpgambling.org</a>.</li>
                  <li>National Suicide Prevention at 1-800-273-8255 or visit <a href="http://www.suicidepreventionlifeline.org">www.suicidepreventionlifeline.org</a>.</li>
                  <li><a href="http://www.gamblersanonymous.org/ga">Gamblers Anonymous</a></li>
                  <li><a href="http://www.gam-anon.org">GAM-ANON</a></li>
                </ul>
                <p>
                  SpinSaga offers all of our customers access to tools that allow you to manage and control your spend and time on site. If you have any questions on how to manage and set self-imposed limits, please contact customer support.
                </p>
                <p>
                  You can choose to set and control the total dollar value of sweepstakes entries you can submit per day, week or month. This gives you full control to set your limits and you will not be able to increase these until the period has completed.
                </p>
                <p>
                  If you feel like you need a break, you can impose a Cool-Off period, where you will be locked out of your account for a period of your choosing up to a maximum of 30 days. During this time, you will not be able to login to your account. Any entries that were open at the time of cool off will remain active and any winnings will be waiting for you once your cool off period is over.
                </p>
                <p>
                  If you feel you may have a problem and wish to permanently exclude yourself from sweepstakes entries, you may opt to self-exclude permanently. In this case your account will be immediately closed and access to your account will be removed. Any open entries will be cancelled, and all Saga Coins and Gold Coins will be revoked as they have no monetary value. We may be obligated to register your self-exclusion with the state regulatory body.
                </p>
                <p>
                  We understand that some individuals may require assistance in managing their gambling activities responsibly. Therefore, you can reach out to our customer support team at <a href="mailto:support@SpinSaga.com">support@SpinSaga.com</a> and an agent will be happy to set your limits for you or apply a full self-exclusion from our site and applications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsibleGaming;
